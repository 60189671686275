<template>
  <div class="m-product-promotions">
    <div v-for="(rule, ruleIndex) in giftRules" :key="rule.rule_id">
      <div v-for="(gift, giftIndex) in rule.gifts" :key="gift.id">
        <SfCheckbox
          class="sf-checkbox"
          @change="toggleGiftCheckbox( getAbsoluteRuleIndex(ruleIndex, giftIndex), rule, gift )"
          :selected="selected[ getAbsoluteRuleIndex(ruleIndex, giftIndex) ]"
          :disabled="isCartBusy"
        >
          <template #label>
            <div class="sf-checkbox__label">
              {{ gift.name }} {{ formatPrice(gift.special_price) }},-
              ({{ $t('Save {discount}', { discount: formatPrice(gift.price - gift.special_price)}) }})
            </div>
          </template>
        </SfCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SfCheckbox from '@storefront-ui/vue/src/components/molecules/SfCheckbox/SfCheckbox.vue';
import flatMap from 'lodash-es/flatMap';
import { formatPrice } from '~/theme/helpers';

export default {
  name: 'MProductPromotions',
  components: {
    SfCheckbox
  },
  props: {
    productsSkus: {
      type: Array,
      required: true
    },
    mainProductSku: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      selected: []
    }
  },
  created () {
    const { mainProductSku, productsSkus } = this
    this.getFreeGifts({ mainProductSku, productsSkus })
  },
  computed: {
    ...mapGetters({
      isCartBusy: 'cart/getCartProcessingStatus'
    }),
    giftRules () {
      if (!this.$store.state['upsell-free-gifts']) return []
      const giftData = this.$store.state['upsell-free-gifts'].gifts[this.mainProductSku]
      return giftData || []
    },
    selectedGifts () {
      const giftsList = flatMap(
        this.giftRules,
        rule => rule.gifts?.map(gift => ({ ...gift, ruleId: rule.rule_id })) || []
      )
      return giftsList.filter((_, giftIndex) => this.selected[giftIndex])
    }
  },
  methods: {
    ...mapActions('upsell-free-gifts', [
      'getFreeGifts'
    ]),
    toggleGiftCheckbox (absoluteGiftIndex, rule, gift) {
      this.$set(this.selected, absoluteGiftIndex, !this.selected[absoluteGiftIndex])
    },
    getAbsoluteRuleIndex (ruleIndex, relativeGiftIndex) {
      return (ruleIndex + 1) * relativeGiftIndex
    },
    formatPrice (price) {
      return formatPrice(price)
    }
  },
  watch: {
    giftRules (rules) {
      if (!Array.isArray(rules) || rules.length === 0) {
        this.selected = []
        return
      }
      this.selected = flatMap(rules, rule => rule.gifts.map(() => !!rule.auto_add))
    },
    selected: {
      handler () {
        this.$emit('input', this.selectedGifts)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";

  .m-product-promotions {
  display: flex;
  justify-content: center;
  @include for_mobile {
    flex-direction: column;
    padding-left: var(--spacer-base);
    padding-right: var(--spacer-base);
  }
  ::v-deep .sf-checkbox {
    --checkbox-font-size: var(--font-size--base);
    --checkbox-size: 0.8rem;
    --checkbox-border-radius: 3px;
  }
}
</style>
